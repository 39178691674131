<template>
  <div v-if="mode === 'view'" class="receiver">
    <div class="title">
      <img src="@/assets/icons/user-yellow.svg" alt="">
      <span>{{ $t('ai_receiver')/*接收人*/ }}</span>
    </div>
    <div class="content">
      <div v-for="(userList, groupName) in groupByGroupName(currentSubscribers)" :key="groupName">
        <div class="name group">
          <img src="@/assets/icons/Group-yellow.svg" alt="">{{ groupName }}
        </div>
        <div class="wrap-names">
          <div class="name" v-for="user in userList" :key="user.userAccount">{{ user.userName }} ({{ user.userAccount }})</div>
        </div>
      </div>
      <!-- <div v-for="(userList, groupName) in groupByGroupName(currentSubscribers)" :key="groupName">
        <div class="name group" >{{ groupName }}</div>
        <div class="wrap-names">
          <div class="name" v-for="user in userList" :key="user.userId">{{ user.userName }} ({{ user.userAccount }})</div>
        </div>
      </div> -->
    </div>
  </div>
  <div v-else class="receiver-edit" :class="{ disabled: isEditRoi }">
    <div class="receiver-header">
      <div class="receiver-search">
        <div class="receiver-label">
          <img src="@/assets/icons/user-yellow.svg" alt="">
          {{ $t('ai_receiver')/*接收人*/ }}
        </div>
        <div class="receiver-input">
          <input type="text" v-model="filterReceiver" :placeholder="$t('ai_unit_name_placeholder')" spellcheck="false">
          <div v-if="filterReceiver.length > 0" class="clear" @click="clearInput">
            <img src="@/assets/icons/clear.svg" alt="">
          </div>
          <img src="@/assets/icons/feather-search-gray.svg" alt="">
        </div>
      </div>
      <InputSwitch v-model="showChecked" :label="$t('ai_show_checked')/*顯示已勾選*/" class="receiver-switch" />
      
    </div>
    <div class="receiver-tree">
      <el-tree
        ref="userTree"
        :data="userTreeList" 
        :props="defaultProps"
        show-checkbox
        node-key="id" 
        :empty-text="$t('tree_no_data')" 
        :default-expanded-keys="expandKeys"
        :filter-node-method="filterNode"
        @check="handleCheck"
      >
        <div
          class="custom-tree-node"
          slot-scope="{ node, data }"
        >
          <div
            class="label"
            :class="{ account: !data.children }"
          >
            <span>{{ node.label }}</span>
          </div>
        </div>
      </el-tree>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Subscribers',
  components: {
    InputSwitch: () => import('@/components/AiBox/base/InputSwitch.vue')
  },
  data() {
    return {
      showChecked: false,
      filterReceiver: '',
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      expandKeys: [],
    }
  },
  computed: {
    ...mapState(['groupList']),
    ...mapState('aibox', ['aiMode', 'userList', 'userTreeList']),
    ...mapState('aiboxLpr', ['mode', 'isEditRoi', 'currentSubscribers']),
    ...mapGetters('aiboxLpr', ['userIndex']),
    selectedUsers() {
      return this.$refs.userTree.getCheckedNodes()
    },
    model() {
      switch (this.aiMode) {
        case 'lpr':
          return 1
        case 'or':
          return 2
        case 'fr':
          return 3
      }
      return 1
    }
  },
  watch: {
    mode() {
      if (this.mode === 'edit') {
        this.filterReceiver = ''
        this.$nextTick(() => {
          this.initUserTree()
        })
      } 
    },
    filterReceiver(val) {
      this.$refs.userTree.filter(val)

    },
    showChecked(val) {
      this.$refs.userTree.filter(val)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initUserTree()
    }) 
  },
  methods: {
    ...mapMutations('aiboxLpr', ['updateCurrentSubscribers']),
    // groupByGroupName(objArr) {
    //   return objArr.reduce((r, a) => {
    //     r[a.groupName] = r[a.groupName] || []
    //     r[a.groupName].push(a)
    //     return r
    //   }, Object.create(null))
    // },
    groupName(groupId) {
      return this.groupList.find(group => group.id === groupId).name
    },
    groupByGroupName(objArr) {
      objArr.forEach(obj => {
        const user = this.userList.find(user => user.index == obj.subscriberId) // index是int, subscriberId是string
        if (user) {
          obj.userName = user.info.name
          obj.userAccount = user.id
          obj.groupId = user.groupId
          obj.groupName = this.groupName(user.groupId)
        }
      })

      return objArr.reduce((r, a) => {
      r[a.groupName] = r[a.groupName] || []
      r[a.groupName].push(a)
      return r
      }, Object.create(null))
    },
    initUserTree() {
      try {
        this.expandKeys = [this.userTreeList[0].id]
          
        // node-key設定為id, 重新勾選起來要用id，這邊資料對應有點亂要仔細
        let checkedNodeIds = this.currentSubscribers.map(data => {
          return data.userAccount
        })
        if (this.$refs.userTree)
          this.$refs.userTree.setCheckedKeys(checkedNodeIds, true)
      } catch (error) {
        console.log('iniUserTree error ===> ',error)
      }
    },
    clearInput() {
      this.filterReceiver = ''
    },
    filterNode(val, data) {
      let checkedNodes = this.$refs.userTree.getCheckedNodes()
      let checked = true
      if (this.showChecked) {
        checked = checkedNodes.findIndex(node => node.id === data.id) >= 0
      }
      
      let filtered = true
      if (this.filterReceiver !== '') {
        const filter = this.filterReceiver.toLowerCase()
        filtered = data.label.toLowerCase().includes(filter)
        const groupName = this.groupList.find(g => g.id === data.groupId).name
        filtered = filtered || groupName.toLowerCase().includes(filter)
      }
      return checked && filtered
    },
    handleCheck() {
      const checkedNodes = this.$refs.userTree.getCheckedNodes()
      // checkedNodes 須先過濾掉含有children的節點
      const temp = checkedNodes.filter(node => !node.children).map(node => ({
        userId: this.userIndex,
        subscriberId: node.index,
        model: this.model,
      }))
      
      this.updateCurrentSubscribers(temp)
    }
  }
}
</script>

<style lang="scss" scoped>
.receiver {
  width: 100%;
  height: 100%;
  padding: 12px 0 12px 12px;

  .title {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 36px;
    font-weight: bold;
    color: #FFE99F;
    margin-bottom: 12px;
    img {
      margin-right: 8px;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 48px);
    overflow: overlay;
  }

  .name {
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
  }

  .group {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #FFC600;
    img {
      margin-right: 8px;
    }
  }

  .wrap-names {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    margin-bottom: 8px;
  }
}

.receiver-edit {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  &.disabled {
    pointer-events: none;
    opacity: 0.2;
  }
}

.receiver-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  box-sizing: border-box;
}

.receiver-search {
  flex: 1;
  display: flex;
}

.receiver-label {
  width: 150px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: #ffffff;
  background: #282942;
  border: 1px solid #9D9D9D;
  border-radius: 8px 0px 0px 8px;
  box-sizing: border-box;
  font-size: 24px;
  line-height: 36px;
  font-weight: normal;
  img {
    margin-right: 8px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(283deg) brightness(104%) contrast(103%); 
  }
}

.receiver-input {
  flex: 1;
  position: relative;
}

.receiver-input input {
  width: 100%;
  font-size: 24px;
  font-weight: 300;
  line-height: 48px;
  padding-left: 24px;
  padding-right: 48px;
  outline: none;
  border: none;
  border-radius: 0px 8px 8px 0px;
  box-sizing: border-box;
}

.clear {
  position: absolute;
  right: 34px;
  top: 4px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.clear img {
  width: 100%;
  height: 100%;
  filter: brightness(0) saturate(100%) invert(39%) sepia(14%) saturate(15%) hue-rotate(331deg) brightness(98%) contrast(91%);
}

.clear:hover {
  filter: brightness(0) saturate(100%) invert(64%) sepia(82%) saturate(741%) hue-rotate(358deg) brightness(108%) contrast(107%);
}

.receiver-input img {
  position: absolute;
  right: 12px;
  top: 14px;
  cursor: pointer;
}

.receiver-switch {
  width: 150px;
}

::placeholder {
  color: #9D9D9D;
}

.receiver-tree {
  width: 100%;
  height: calc(100% - 54px);
  // flex: 1;
  // overflow: overlay;
  border-radius: 8px;
}

/* el-tree */
::v-deep .el-tree {
  border: 1px solid #9D9D9D;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  overflow: overlay;
}

::v-deep .el-tree-node {
  white-space: normal;
}

::v-deep .el-tree-node__content {
  height: 100%;
  padding: 2px 0;
}

::v-deep .el-tree-node__label {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  color: #191919;
}

/* 調整三角形大小 */
::v-deep .el-tree-node__expand-icon {
  font-size: 20px;
}

/* 調整三角形顏色需排除 */
::v-deep .el-tree-node__expand-icon:not(.is-leaf) {
  color: #191919;
}

/* 父層文字顏色 */
::v-deep .el-tree-node__expand-icon:not(.is-leaf) ~ .el-tree-node__label {
  color: #4361EE;
}

.custom-tree-node {
  font-size: 20px;
}

.custom-tree-node .label {
  color: #4361EE;
}

.custom-tree-node .label.account {
  color: #191919;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, 
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #FFC600;
  border-color: #FFC600;
  width: 16px;
  height: 16px;
}

::v-deep .el-checkbox__inner {
  border-color: #191919;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  top: 3px;
  height: 4px;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
  border: 2px solid #FFF;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 4px;
  top: 1px;
  width: 4px;
}    

</style>